import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Not Found" />
      <section className="container mt-5">
        <div className="mw-600">
          <h4>Oops! Page Not Found</h4>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
